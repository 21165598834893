(function () {
	'use strict';
	angular
		.module('app')
		.factory('manageUser', [
			'$q',
			'$http',
			'$location',
			'$timeout',
			'utilities',
			'dataStore',
			'hash',
			'seedcodeCalendar',
			'environment',
			'urlParameters',
			manageUser,
		]);

	function manageUser(
		$q,
		$http,
		$location,
		$timeout,
		utilities,
		dataStore,
		hash,
		seedcodeCalendar,
		environment,
		urlParameters
	) {
		const useStaticData =
			_CONFIG.DBK_USE_STATIC_DATA || urlParameters?.staticConfigPath;
		var signIn = {};
		signIn.token = dataStore.getState('userToken', null, true, true);

		var salesforceUser = initSalesforceUser();
		var shareID;
		var authDeferred;
		var authStateChanged;
		var linkedUserToken;
		var linkedAccount;

		return {
			clearStoredAuthTokens: clearStoredAuthTokens,
			accountExists: accountExists,
			signInGoogle: signInGoogle,
			auth: auth,
			getAuth: getAuth,
			setShareID: setShareID,
			getShareID: getShareID,
			createDayBackUser: createDayBackUser,
			reset: reset,
			logout: logout,
			resetPassword: resetPassword,
			changePassword: changePassword,
			getSignIn: getSignIn,
			getLinkedUserToken: getLinkedUserToken,
			setLinkedUserToken: setLinkedUserToken,
			createUserToken: createUserToken,
			createGroupToken: createGroupToken,
			getLinkedAccount: getLinkedAccount,
		};

		function initSalesforceUser() {
			var deferred = $q.defer();
			if (fbk.isSalesforce()) {
				fbk.initialize(salesforceStart);
			} else {
				return false;
			}
			//Callback function once we have queried salesforce
			function salesforceStart(result) {
				if (!result || result.errorCode) {
					var modalConfig = {
						title:
							result && result.errorCode
								? result.errorCode
								: 'Error Retrieving SalesForce Data',
						content:
							result && result.message
								? result.message +
								  "<p>We recommend you reload the page to resolve this; It may take a few attempts. If that doesn't work, please get in touch.</p>"
								: 'There was an error retrieving data from salesforce. We recommend you reload the page to resolve this.',
						cancelButtonText: 'Contact Us',
						cancelFunction: function () {
							utilities.help(
								'https://www.dayback.com/contact/',
								'https://www.dayback.com/contact/',
								true
							);
							fbk.reloadPage();
						},
						confirmButtonText: 'Reload',
						confirmFunction: function () {
							fbk.reloadPage();
						},
					};
					utilities.showModal(
						modalConfig.title,
						modalConfig.content,
						modalConfig.cancelButtonText,
						modalConfig.cancelFunction,
						modalConfig.confirmButtonText,
						modalConfig.confirmFunction
					);
				} else {
					deferred.resolve(result);
				}
			}
			return deferred.promise;
		}

		function clearStoredAuthTokens(userID, successCallback, errorCallback) {
			// Activate subscription
			$http({
				method: 'DELETE',
				url: _CONFIG.DBK_API_BASEURL + 'user/tokens',
				params: {
					uid: userID,
				},
			})
				.then(function (response) {
					//Run something after successful Delete
					if (successCallback) {
						successCallback();
					}
				})
				.catch(function (error) {
					//Run something on error
					if (errorCallback) {
						errorCallback();
					}
				});
		}

		function accountExists(
			account,
			provider,
			foundCallback,
			notFoundCallback,
			errorCallback
		) {
			$http({
				method: 'GET',
				url:
					_CONFIG.DBK_API_BASEURL +
					'account/provider?account=' +
					encodeURIComponent(account.toLowerCase()) +
					'&provider=' +
					encodeURIComponent(provider),
			})
				.then(function (response) {
					var authPlatform = response.data;
					//Run something after successful get
					if (foundCallback) {
						foundCallback(authPlatform);
					}
				})
				.catch(function (error) {
					if (error && error.status == 404) {
						//Run something on error
						if (notFoundCallback) {
							notFoundCallback();
						}
					} else {
						if (errorCallback) {
							errorCallback(error);
						}
						utilities.showModal(
							'Server Interruption',
							'There was an issue communicating with the server. Please wait a moment and try again',
							null,
							null,
							'OK',
							null
						);
					}
				});
		}

		function signInGoogle(
			successCallback,
			errorCallback,
			cancelledCallback,
			signUp
		) {
			var provider = new firebase.auth.GoogleAuthProvider();

			provider.addScope('profile');
			provider.addScope('email');

			if (
				environment.isMobileDevice ||
				environment.isFilemakerJS ||
				environment.isStandAlone
			) {
				signInGoogleRedirect(provider);
			} else {
				signInGooglePopup(
					provider,
					successCallback,
					errorCallback,
					cancelledCallback
				);
			}
		}

		function signInGoogleRedirect(provider) {
			dataStore.saveState('authenticating', true, true);
			firebase.auth().signInWithRedirect(provider);
		}

		function signInGooglePopup(
			provider,
			successCallback,
			errorCallback,
			cancelledCallback
		) {
			// Using a popup.
			firebase
				.auth()
				.signInWithPopup(provider)
				.then(function (result) {
					// This gives you a Google Access Token.
					var token = result.credential.accessToken;
					// The signed-in user info.
					var user = result.user;
					var profile = result.additionalUserInfo.profile;

					var userID = user.uid;
					var firstName = profile.given_name;
					var lastName = profile.family_name;
					var userName = profile.email;

					accountExists(
						userName,
						'google.com',
						function (authPlatform) {
							if (successCallback) {
								successCallback(authPlatform);
							}
						},
						function () {
							if (errorCallback) {
								errorCallback(userName, firstName, lastName);
							}
						},
						function (error) {
							cancelledCallback(error);
						}
					);

					// daybackIO.userExists(userID, function(user) {
					// 	if (user) {
					// 		if (successCallback) {
					// 			successCallback();
					// 		}
					// 	}
					// 	else {
					// 		if (errorCallback) {
					// 			errorCallback();
					// 		}
					// 	}
					// });
				})
				.catch(function (error) {
					if (cancelledCallback) {
						cancelledCallback(error);
					}
				});
		}

		function getSignIn() {
			return signIn;
		}

		function auth(email, password, remember, callback, fromSignIn) {
			var session = remember ? 'default' : 'sessionOnly';

			if (fromSignIn && authStateChanged) {
				reset();
			}
			firebase
				.auth()
				.signInWithEmailAndPassword(email, password)
				.then(function (data) {
					//Set authentication type type
					signIn.authType = 'email';

					signIn.token = null;
					signIn.email = data.user.email;

					if (signIn.isTemporaryPassword) {
						signIn.password = password;
					}

					if (data && data.user) {
						signIn.token = createUserToken(
							data.user.email,
							data.user.uid,
							data.user.refreshToken
						);
					}

					authDeferred = initAuth();
					var authMe = authDeferred;
					authMe.then(function (result) {
						if (remember && signIn.token) {
							dataStore.saveState(
								'userToken',
								signIn.token,
								null,
								true,
								true
							);
						} else {
							//Set persistence so the login expires on window close
							firebase
								.auth()
								.setPersistence(
									firebase.auth.Auth.Persistence.SESSION
								)
								.then(function () {
									// Existing and future Auth states are now persisted in the current
									// session only. Closing the window would clear any existing state even if
									// a user forgets to sign out.
								});
						}
						if (callback) {
							callback(data);
						}
					});
				})
				.catch(function (error) {
					//There was an error
					//Set our result object to contain an error boolean for easy reference
					error.error = true;
					if (callback) {
						callback(error);
					}
				});
		}
		function authAnonymously(uid) {
			var deferred = $q.defer();
			var authData = {};
			var preventAuth = _CONFIG.DBK_PREVENT_FIREBASE_AUTH;
			var authTryCount = 0;
			var errors = [];

			if (preventAuth) {
				// Prevent auth would be set in "limp mode" when we don't have
				// any connection to firebase
				if (!shareID && (uid === 'undefined' || !uid)) {
					deferred.reject();
					return deferred.promise;
				}

				//Set authentication type type
				authData.authType = 'anonymous';
				if (uid) {
					authData.uid = uid;
				}
				deferred.resolve(authData);
				return deferred.promise;
			}

			authenticate();

			function authenticate() {
				firebase
					.auth()
					.signInAnonymously()
					.then(function (data) {
						//Set authentication type type
						authData.authType = 'anonymous';
						if (uid) {
							authData.uid = uid;
						}

						deferred.resolve(authData);
					})
					.catch(function (error) {
						//An error
						authTryCount++;
						errors.push(error);
						if (authTryCount <= 1) {
							// Retry auth 2 times increasing retry wait each time
							window.setTimeout(() => {
								authenticate();
							}, 500 * authTryCount);
							return;
						}
						if (fbk.isSalesforce()) {
							const errorMessage = errors
								? JSON.stringify(errors)
								: '';
							const lastAuthErrorState = dataStore.getState(
								'authError',
								true,
								true,
								true
							);
							const errorTime = new Date().getTime();
							if (
								!lastAuthErrorState ||
								lastAuthErrorState < errorTime - 1000 * 60 * 60
							) {
								dataStore.saveState(
									'authError',
									errorTime,
									true,
									true,
									true
								);
								window.location.reload();
								return;
							}
							// Show modal for salesforce that has a retry option
							utilities.showModal(
								'Authentication Failed',
								'There was a problem communicating with the DayBack authentication servers.',
								null,
								null,
								'Retry',
								() => {
									authTryCount = 0;
									window.location.reload();
								}
							);
						} else {
							deferred.reject();
							console.log('Authentication Failed!', error);
							logout();
						}
					});
			}
			return deferred.promise;
		}

		function authWithAccountOnly(account) {
			var deferred = $q.defer();
			var authData = {};

			var preventAuth =
				_CONFIG.DBK_PREVENT_FIREBASE_AUTH || useStaticData;

			if (preventAuth) {
				//Set authentication data
				authData.authType = 'token';
				authData.account = account;
				authData.localAuth = true;

				linkedAccount = account;

				deferred.resolve(authData);

				return deferred.promise;
			}

			firebase
				.auth()
				.signInAnonymously()
				.then(function (data) {
					//Set authentication type type
					authData.authType = 'token';

					authData.account = account;

					linkedAccount = account;

					deferred.resolve(authData);
				})
				.catch(function (error) {
					console.log('error', error);
					deferred.reject();
					logout();
				});
			return deferred.promise;
		}

		function authWithToken(token) {
			var deferred = $q.defer();
			var authData = {};
			var email = crypt.runGetHashProperty(token, 'email');
			var uid = crypt.runGetHashProperty(token, 'uid');
			var created = crypt.runGetHashProperty(token, 'created');

			var preventAuth =
				_CONFIG.DBK_PREVENT_FIREBASE_AUTH || useStaticData;

			if (uid === 'undefined' || !uid) {
				deferred.reject();
				logout();
			}

			if (preventAuth) {
				//Set authentication data
				authData.authType = 'token';
				authData.account = email;
				authData.uid = uid;
				authData.token = token;
				authData.localAuth = true;

				deferred.resolve(authData);

				return deferred.promise;
			}

			firebase
				.auth()
				.signInAnonymously()
				.then(function (data) {
					//Set authentication type type
					authData.authType = 'token';

					authData.account = email;
					authData.uid = uid;

					authData.token = token;

					deferred.resolve(authData);
				})
				.catch(function (error) {
					console.log('error', error);
					deferred.reject();
					logout();
				});
			return deferred.promise;
		}

		function initAuth() {
			var deferred = $q.defer();
			var authTokenDeferred;
			var share;
			if (shareID) {
				share = {
					id: shareID,
				};
			}
			//Check if this is a share, short circuit if it is as we won't allow shares inside active accounts currently
			if (shareID) {
				authTokenDeferred = authAnonymously();
				authTokenDeferred.then(function (authData) {
					authData.public = true;
					authWatcher(authData);
				});
			}
			//Check if we are currently in salesforce
			else if (salesforceUser) {
				salesforceUser.then(function (result) {
					createSalesforceAuthData(result, function (authData) {
						authWatcher(authData);
					});
				});
			} else if (
				urlParameters.userGroupToken &&
				urlParameters.userEmail
			) {
				var linkedGroupToken = urlParameters.userGroupToken;
				var linkedGroupID = linkedGroupToken
					? crypt.runGetHashProperty(linkedGroupToken, 'uid')
					: null;
				var userAccount = urlParameters.userEmail;

				if (linkedGroupID && userAccount) {
					authTokenDeferred = authWithAccountOnly(userAccount);
					authTokenDeferred.then(function (authData) {
						authWatcher(signIn);
					});
				} else {
					// missing or problem with linked groupID - Sign Out
					logout(null);
				}
			}
			//There is a user token in the url use that to sign in
			else if (
				signIn.authType !== 'email' &&
				(urlParameters.userToken ||
					$location.search().userToken ||
					linkedUserToken ||
					signIn.token)
			) {
				linkedUserToken =
					urlParameters.userToken ||
					$location.search().userToken ||
					linkedUserToken;
				signIn.token =
					urlParameters.userToken ||
					$location.search().userToken ||
					linkedUserToken ||
					signIn.token;
				authTokenDeferred = authWithToken(signIn.token);
				authTokenDeferred.then(function (authData) {
					authWatcher(authData);
				});
			} else if (signIn.authType === 'google') {
				// Do something with google sign in here
			} else {
				authWatcher(signIn);
			}

			function authWatcher(authData) {
				var preventAuth = _CONFIG.DBK_PREVENT_FIREBASE_AUTH;
				var auth;

				signIn = authData;

				if (preventAuth || authData?.localAuth) {
					if (
						!authData ||
						(!authData.uid && !linkedAccount && !authData.public)
					) {
						deferred.reject('User is logged out');
						return;
					}

					auth = {
						uid: authData.uid,
					};

					authSuccess(auth);

					return;
				}

				//Watch for auth state changes. Assign to var so we can kill the observer
				authStateChanged = firebase
					.auth()
					.onAuthStateChanged(function (auth) {
						if (auth) {
							//Assign our signIn token to the auth object Todo: Is the auth.token property used anywhere? Can we remove it?
							auth.token = signIn.token;

							authSuccess(auth);
						} else if (!salesforceUser && !authTokenDeferred) {
							console.log('User is logged out');
							deferred.reject('User is logged out');
							return;
						}
					});
			}

			function authSuccess(result) {
				if (!result) {
					deferred.reject();
				} else {
					//Add our sign in credentials to the auth object for cross comparisons
					result.share = share;
					result.signIn = signIn;
					deferred.resolve(result);
				}
			}

			return deferred.promise;
		}

		function getAuth(routeChange) {
			var deferred = $q.defer();
			//Reset auth status in some cases
			if (routeChange && getShareID()) {
				authDeferred = null;
			}
			if (!authDeferred) {
				authDeferred = initAuth();
			}
			authDeferred.then(
				function (result) {
					deferred.resolve(result);
				},
				function (error) {
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}

		function setShareID(id) {
			shareID = id;
		}

		function getShareID() {
			return shareID;
		}
		function createDayBackUser(
			userName,
			password,
			firstName,
			lastName,
			groupName,
			origin,
			isTemporaryPassword,
			signUpType,
			provider,
			remember,
			doAuth,
			callback,
			preventReset
		) {
			var addUserCallback = doAuth ? authUser : userCreated;

			if (provider !== 'google') {
				addUserAccount(userName, password, addUserCallback);
			} else if (provider === 'google') {
				firebase.auth().onAuthStateChanged(function (user) {
					if (user && callback) {
						// User is signed in.
						var userID = user.uid;
						callback(user);
					}
				});
			}

			function authUser(result) {
				if (!result || result.message) {
					callback(result);
					return;
				}
				auth(
					userName,
					password,
					remember,
					function (authResult) {
						//We want to call userCreated passing in the result from adding the user so callback data is consistent
						userCreated(result, authResult);
					},
					!preventReset
				);
			}

			function userCreated(result, authResult) {
				var userToken;
				if (result) {
					if (
						signUpType === 'sharing' &&
						authResult &&
						authResult.token
					) {
						userToken = authResult.token;
						filemakerSharing(userToken);
					}

					if (callback) {
						callback(result);
					}
				}
			}

			function filemakerSharing(userToken) {
				var sharing = {
					userToken: userToken,
				};

				var popover = {
					id: 'sharingSetup',
					controller: 'ModalCtrl',
					container: $('body'),
					type: 'modal', // modal or popover
					width: 600,
					// positionX: e.pageX,
					// positionY: e.pageY,
					data: sharing,
					dataTitle: 'sharing',
					destroy: true,
					onShow: '',
					onShown: '',
					onHide: '',
					onHidden: function () {
						//Unset prevention of trial dialogs
						hash.setPreventDialogs(false);
					},
					show: true,
				};

				//Prevent trial dialogs
				hash.setPreventDialogs(true);
				//Show popover
				utilities.popover(
					popover,
					'<div ng-include="\'app/settings/account/fm-sharing.html\'"></div>'
				);
			}
		}

		function addUserAccount(userName, password, callback) {
			firebase
				.auth()
				.createUserWithEmailAndPassword(userName, password)
				.then(function (userData) {
					if (callback) {
						callback(userData);
					}
				})
				.catch(function (error) {
					if (callback) {
						callback(error);
					}
				});
		}

		function logout(callback, preventLocationChange, fromSignOut) {
			var preventAuth = _CONFIG.DBK_PREVENT_FIREBASE_AUTH;

			if (!preventAuth) {
				firebase
					.auth()
					.signOut()
					.then(function () {
						//Sign-out was successful
						completeSignOut();
					})
					.catch(function (error) {
						//There was an error signing out
						if (fromSignOut) {
							signOutError();
						} else {
							completeSignOut();
						}
					});
			} else {
				if (fromSignOut) {
					// Only run this if the attempt was from an explicit signout
					// If firebase is not working we need to clear their indexdb storage
					var req = indexedDB.deleteDatabase('firebaseLocalStorage');
					req.onsuccess = function () {
						console.log('Deleted database successfully');
						completeSignOut();
					};
					req.onerror = function () {
						console.log("Couldn't delete database");
						signOutError();
					};
					req.onblocked = function () {
						console.log(
							"Couldn't delete database due to the operation being blocked"
						);
						signOutError();
					};
				}
			}

			function completeSignOut() {
				// Clear crypt hash
				hash.init();

				//Clear out any cookies or local storage items
				dataStore.clearAllSavedStates();

				$timeout(function () {
					if (callback) {
						callback();
					}
					if (!preventLocationChange) {
						$location.path('/sign-in');
					}
					reset(true);
				}, 0);
			}

			function signOutError() {
				utilities.showModal(
					'Sign-out Failed',
					'There was a problem signing out of DayBack. Please try again later.',
					null,
					null,
					'OK',
					null
				);
			}
		}

		function reset(fromSignOut) {
			//Stop the auth state observer
			if (authStateChanged) {
				authStateChanged();
			}

			//Clear auth promise
			authDeferred = null;

			signIn = {};

			// Clear crypt hash
			hash.init();

			// Clear state
			// Wrap in timeout so the values don't dissapear before the signout has completed
			if (fromSignOut) {
				window.setTimeout(function () {
					seedcodeCalendar.reset();
				}, 500);
			} else {
				seedcodeCalendar.reset();
			}
		}

		function resetPassword(email, callback) {
			var result = {};
			firebase
				.auth()
				.sendPasswordResetEmail(email)
				.then(function (data) {
					// Password reset email sent.
					result.success = true;
					result.message = 'Password reset email sent successfully!';
					if (callback) {
						callback(result);
					}
				})
				.catch(function (error) {
					if (!error) {
						error = {};
					}

					// Error occurred. Inspect error.code.
					result.success = false;
					switch (error.code) {
						case 'auth/invalid-email':
							result.message =
								'The email address is badly formatted.';
							break;
						case 'auth/user-not-found':
							result.message =
								'The specified user account does not exist.';
							break;
						default:
							result.message = 'Error resetting password:';
					}

					if (callback) {
						callback(result);
					}
				});
		}

		function changePassword(email, oldPassword, newPassword, callback) {
			var authData = getAuth();
			var remember;
			var credentials;
			var result = {};

			credentials = firebase.auth.EmailAuthProvider.credential(
				email,
				oldPassword
			);

			// user.reauthenticateAndRetrieveDataWithCredential(credentials)
			firebase
				.auth()
				.signInWithEmailAndPassword(email, oldPassword)
				.then(function (data) {
					updatePassword();
				})
				.catch(function (error) {
					result.success = false;
					result.message = error.message;
					//Run callback to display error
					if (callback) {
						callback(result);
					}
				});

			function updatePassword() {
				firebase
					.auth()
					.currentUser.updatePassword(newPassword)
					.then(function () {
						result.success = true;
						result.message = 'Password changed successfully';

						if (authData && authData.expires) {
							remember =
								authData.expires > new Date().getTime() / 1000;
						}
						//Log the user in with the new password
						auth(email, newPassword, remember, onLogin);
					})
					.catch(function (error) {
						result.success = false;
						result.message = error.message;
						//Run callback to display error
						if (callback) {
							callback(result);
						}
					});
			}

			function onLogin(loginResult) {
				if (callback) {
					callback(result);
				}
			}
		}

		function createSalesforceAuthData(userData, successCallback) {
			if (userData && userData.salesforce) {
				var id = crypt.createHashToken(
					'sf-' + userData.accountId,
					userData.organizationId + userData.accountId
				);
				var authAnonymouslyDeferred = authAnonymously(
					userData.accountName
				);
				authAnonymouslyDeferred.then(function (authData) {
					authData.uid = id;
					authData.account = userData.accountName;
					authData.email = userData.accountEmail;
					authData.name = userData.accountFullName;
					authData.groupID = crypt.createHashToken(
						'sf-org-' + userData.organizationId,
						userData.organizationId
					);
					authData.groupName = userData.organizationName;
					authData.sf = userData;
					if (successCallback) {
						successCallback(authData);
					}
				});
			} else {
				//We aren't a salesforce user but we thought we were - do something here to log them in if we can
			}
		}

		function getLinkedUserToken() {
			return linkedUserToken;
		}

		function setLinkedUserToken(userToken) {
			linkedUserToken = userToken;
			return linkedUserToken;
		}

		function getLinkedAccount() {
			return linkedAccount;
		}

		function setLinkedAccount(linkedAccount) {
			linkedAccount = linkedAccount;
			return linkedAccount;
		}

		function createUserToken(email, uid, idToken) {
			var token = '';
			token = crypt.runUpdateHashValue(token, 'email', email);
			token = crypt.runUpdateHashValue(token, 'uid', uid);
			token = crypt.runUpdateHashValue(token, 'idToken', idToken);
			token = crypt.runUpdateHashValue(
				token,
				'created',
				new Date().getTime()
			);

			return token;
		}

		function createGroupToken(member, uid) {
			var token = '';
			token = crypt.runUpdateHashValue(token, 'member', member);
			token = crypt.runUpdateHashValue(token, 'uid', uid);
			token = crypt.runUpdateHashValue(
				token,
				'created',
				new Date().getTime()
			);

			return token;
		}
	}
})();
